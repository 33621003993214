import {createI18n} from 'vue-i18n'

import enDefault from '@/i18n/en/default.js'
import enMonths from '@/i18n/en/months.js'

import frDefault from '@/i18n/fr/default.js'
import frMonths from '@/i18n/fr/months.js'

export default createI18n({
    locale: 'fr',
    fallbackLocale: 'fr',
    messages: {
        en: {
            ...enDefault,
            months: enMonths
        },
        fr: {
            ...frDefault,
            months: frMonths
        },
    }
})
