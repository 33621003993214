export default {
    short: {
        0: 'Jan',
        1: 'Fev',
        2: 'Mar',
        3: 'Avr',
        4: 'Mai',
        5: 'Juin',
        6: 'Juil',
        7: 'Août',
        8: 'Sep',
        9: 'Oct',
        10: 'Nov',
        11: 'Déc',
    }
}