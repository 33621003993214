export const app = {
    state: {
        client: {
            name: ''
        },
        modal: {
            employee_id: null
        }
    },
    mutations: {
        updateClient(state, client) {
            state.client = client
        },
        updateCurrentEmployeeId(state, id) {
            state.modal.employee_id = id
        }
    },
    actions: {
        updateClient(context, client) {
            context.commit('updateClient', client)
        },
        updateCurrentEmployeeId(context, id) {
            context.commit('updateCurrentEmployeeId', id)
        }
    }
}