<template>
    <p :class="['hat', {'--floating': floating}]">{{ text }}</p>
</template>

<script>
export default {
  name: 'SectionHat',
  props: {
    floating: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      required: true
    }
  }
}
</script>