import {createRouter, createWebHistory} from 'vue-router'
import { generateRoutes } from '@/extensions/langRouter'

const routes = [
	{
		path: '/:pathMatch(.*)*',
		name: 'catch-all',
		component: () => import('@/views/Presentation.vue')
	}
]

const localizedRoutes = generateRoutes(routes, 'fr', 'en')

export default createRouter({
	history: createWebHistory(import.meta.env.BASE_URL),
	linkActiveClass: '-active',
	linkExactActiveClass: '-exact-active',
	routes: localizedRoutes,
	scrollBehavior(to) {
		return to.hash
			? {el: to.hash, behavior: 'smooth'}
			: { top: 0, behavior: 'smooth' }
	}
})