const defaultSlide = import.meta.env.VITE_APP_DEFAULT_SLIDE ? parseInt(import.meta.env.VITE_APP_DEFAULT_SLIDE) : 0

export const slide = {
    state: {
        current: defaultSlide,
        hovered: defaultSlide,
        items: [],
        locked: false,
        reset: null,
        speed: {
            navigation: 200,
            reveal: 700
        },
        visible: null
    },
    mutations: {
        resetHoveredSlide(state, index) {
            if(state.reset) clearTimeout(state.reset)
            state.reset = setTimeout(() => {
                if(state.hovered === index) state.hovered = state.current
            }, 2000)

        },
        updateCurrentSlide(state, current) {
            if(!state.locked) {
                state.locked = true

                state.visible = null
                setTimeout(() => {
                    state.current = current
                    state.hovered = current
                    setTimeout(() => {
                        state.visible = current
                        setTimeout(() => state.locked = false, state.speed.reveal)
                    }, state.speed.navigation)
                },state.speed.reveal)
            }
        },
        updateHoveredSlide(state, index) {
            clearTimeout(state.reset)
            state.hovered = index
        },
        updateSlides(state, slides) {
            state.items = slides
        },
        updateVisibleSlide(state, index) {
            state.visible = index
        }
    },
    actions: {
        resetHoveredSlide(context, index) {
            context.commit('resetHoveredSlide', index)
        },
        updateCurrentBackward(context) {
            if(context.state.current - 1 >= 0) {
                context.commit('updateCurrentSlide', context.state.current - 1)
            }
        },
        updateCurrentForward(context) {
            if(context.state.current + 1 < context.state.items.length) {
                context.commit('updateCurrentSlide', context.state.current + 1)
            }
        },
        updateCurrentSlide(context, index) {
            context.commit('updateCurrentSlide', index)
        },
        updateHoveredSlide(context, index) {
            context.commit('updateHoveredSlide', index)
        },
        updateSlides(context, slides) {
            context.commit('updateSlides', slides)
        },
        updateVisibleSlide(context, index) {
            context.commit('updateVisibleSlide', index)
        }
    }
}