<template>
  <Transition name="fade">
    <aside v-if="$store.state.app.modal.employee_id" class="modal" @click="closeModal">
      <div class="employee__modal" @click.stop>
        <div class="employee__modal-top">
          <button class="button --round" @click="closeModal">
            <Icon id="x"/>
          </button>
        </div>
        <div class="employee__modal-content">
          <div class="employee__modal-infos">
            <div class="employee__modal-job">
              <SectionHat :text="employee.job" floating/>
            </div>
            <h2 class="employee__modal-name">{{ employee.name }}</h2>
            <RawHtml :html="employee.description"/>
          </div>
          <Image :source="employee.image" class="employee__modal-image"/>
        </div>
      </div>
    </aside>
  </Transition>
</template>

<script>
import {Icon, Image, RawHtml} from '@/components/global'
import {SectionHat} from '@/components/small'

export default {
  name: 'Modal',
  components: {Icon, Image, RawHtml, SectionHat},
  computed: {
    employee() {
      return this.$beet.employees[this.$store.state.app.modal.employee_id] ?? null
    }
  },
  methods: {
    closeModal() {
      this.$store.dispatch('updateCurrentEmployeeId', null)
      document.querySelector('[data-slide-container]').focus()
    }
  }
}
</script>