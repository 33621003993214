import { createStore } from 'vuex'

import {app} from '@/store/app'
import {auth} from '@/store/auth'
import {slide} from '@/store/slide'

export default createStore({
  modules: {
    app,
    auth,
    slide
  }
})
