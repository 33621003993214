export const auth = {
    state: {
        logged_in: import.meta.env.VITE_APP_DEFAULT_LOGGED_IN === 'true',
    },
    mutations: {
        updateLoggedIn(state, logged_in) {
            state.logged_in = logged_in
        }
    },
    actions: {
        updateLoggedIn(context, logged_in = true) {
            context.commit('updateLoggedIn', logged_in)
        }
    }
}