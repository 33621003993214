<template>
  <p v-if="!mq.mdPlus" class="notice">{{ $t('desktop_only') }}</p>
  <template v-if="$beet.isReady">
    <RouterView v-if="mq.mdPlus"/>
    <Modal/>
  </template>
  <Transition name="fade">
    <Loader v-if="!$beet.isReady"/>
  </Transition>
</template>

<script>
import {Modal} from '@/components/sections'
import {Loader} from '@/components/small'
import {useLangRouter} from '@/extensions/langRouter'

export default {
  name: 'App',
  inject: ['mq'],
  setup() {
    useLangRouter()
  },
  components: {Loader, Modal},
  watch: {
    '$i18n.locale': {
      handler: function (to) {
        this.$beet.fetchCoreData(to)
      },
      immediate: true
    }
  },
  updated() {
    this.$nextTick(() => {
      const el = this.$route.hash ? document.querySelector(this.$route.hash) : null
      el ?
          el.scrollIntoView({behavior: 'smooth', block: 'start'}) :
          window.scrollTo({top: 0, behavior: 'smooth'})
    })
  }
}
</script>
